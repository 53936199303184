import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './../../assets/css/output.module.css';
import $ from 'jquery';
import { displayError } from '../../reducers/snackbar'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubscription, fetchInvoices, fetchCourses, pauseSubscription, resumeSubscription } from '../../reducers/billingDetails'
import { displayPrice } from '../../components/currencyPrice'
import { formatDate } from '../../utils/date'
import { downloadInvoice } from '../../reducers/billingDetails'
import { fetchUpgradableSubscription, reactivateSubscription } from '../../reducers/billingDetails'
import { setCart } from '../../reducers/cart'
import SideBar from '../../components/layout/sidebar'
import visa from './../../assets/img/payment-cards/cn-payment-visa.svg'
import mastercard from './../../assets/img/payment-cards/cn-payment-mastercard.svg'
import amex from './../../assets/img/payment-cards/cn-payment-amex.svg'
import discover from './../../assets/img/payment-cards/cn-payment-discover.svg'
import jcb from './../../assets/img/payment-cards/cn-payment-jcb.svg'
import diners from './../../assets/img/payment-cards/cn-payment-dinersclub.svg'
import { initChargebee } from '../../reducers/chargebee';
import { createCheckoutForUpdatePaymentSource } from '../../reducers/chargebee';
import { resetEstimate } from '../../reducers/cart';
import Footer from '../../components/layout/footer';
import { vvPlanPage2 } from '../../settings.js/dev';
import { getTranslation } from '../../reducers/translation';
import { TRANSLATION_TYPE } from '../../utils/constants';
import { getInterpolatedString } from '../../utils/text';
import Localization from '../../components/translation';

function ViewBilling() {
    const paymentProcessingIssue = "Not Paid";
    const pageLength = 10;
    const [invoiceIndex, setInvoiceIndex] = useState({
        current: 0,
        min: 0,
        max: 0,
        countPerPage: pageLength
    });
    const [courseOnly, setCourseOnly] = useState(false);
    const navigate = useNavigate();

    const { subscription, invoices, upgradableSubscription, courses, isCTInvoiceDunning } = useSelector((state) => state.billingDetails);
    const { isCbInitiated, cbRetentionAppId } = useSelector((state) => state.chargebee);
    const { cbInstance } = useSelector((state) => state.chargebee);
    var isMobileSubscription = (subscription?.channel == 'PlayStore') || (subscription?.channel == 'AppStore');
    var hideUpgradables = (subscription?.status === "Cancelled" || subscription?.status === "Paused" || isCTInvoiceDunning); const t = useSelector(getTranslation)
    const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
    const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetEstimate());
        fetchSubscriptionDetails();

        if (!isCbInitiated) {
            dispatch(initChargebee());
        }

    }, [])

    const fetchSubscriptionDetails = () => {
        dispatch(fetchSubscription()).catch((error) => {
            dispatch(displayError(error));
        }).then((response) => {
            setCourseOnly(response.plan == null);
        })

        dispatch(fetchInvoices()).catch((error) => {
            dispatch(displayError(error));
        })

        dispatch(fetchUpgradableSubscription()).catch((error) => {
            // dispatch(displayError(error));
        })
    }

    useEffect(() => {
        $('[data-toggle]').on('click', function (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            const $this = $(this);
            const $target = $($this.attr('data-toggle'));
            $target.attr('aria-hidden', $target.attr('aria-hidden') == 'false' ? true : false);
        });
    }, [subscription, upgradableSubscription]);

    useEffect(() => {
        setInvoiceIndex({ ...invoiceIndex, max: invoices.length > 0 ? Math.ceil(invoices.length / pageLength) - 1 : 0 });
    }, [invoices])

    const downloadInvoiceAttachment = (invoice) => {

        dispatch(downloadInvoice(invoice?.invoiceId, invoice?.invoiceOrigin)).then((response) => {
            // Create a hidden anchor element to trigger the download
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = response.downloadUrl;
            a.download = `${invoice?.invoiceId}.pdf`; // Specify the desired file name
            if (invoice?.invoiceOrigin === 'ZUORA')
                a.target = "_blank"; // Open in a new tab

            // Trigger a click event on the anchor element to start the download
            document.body.appendChild(a);
            a.click();

            // Clean up after the download is initiated
            window.URL.revokeObjectURL(response.downloadUrl);
            document.body.removeChild(a);
        }).catch((error) => {
            dispatch(displayError(error));
        })
    }

    const reactivateSub = (e) => {
        navigateToCheckout(e, true);
        // dispatch(reactivateSubscription()).then((response) => {
        //     fetchSubscriptionDetails();
        // }).catch((error) => {
        //     dispatch(displayError(error));
        // })
    }

    const resumeSubs = (e) => {
        dispatch(resumeSubscription()).then((response) => {
            fetchSubscriptionDetails();
        }).catch((error) => {
            dispatch(displayError(error));
        })
    }

    const handleUpdatePM = () => {
        dispatch(createCheckoutForUpdatePaymentSource()).then((response) => {
            openPMCheckout(response)
        }).catch((error) => {
            dispatch(displayError(error));
        })
    }

    const openPMCheckout = (payload) => {
        cbInstance.openCheckout({
            hostedPage() {
                const hostedPageData = payload
                return Promise.resolve(hostedPageData);
            },
            close() {
                dispatch(fetchSubscription()).catch((error) => {
                    dispatch(displayError(error));
                })
            }
        });
    }

    const getCurrentPageInvoice = () => {
        return invoices.slice(invoiceIndex.current * pageLength, invoiceIndex.current * pageLength + pageLength);
    }

    const updateInvoiceIndex = (event) => {
        const button = event.target.closest("button");
        if (button) {
            setInvoiceIndex({ ...invoiceIndex, current: parseInt(button.id) });
        }

    }

    const navigateToNextInvoicePage = () => {
        if (invoiceIndex.current < invoiceIndex.max) {
            setInvoiceIndex({ ...invoiceIndex, current: ++invoiceIndex.current });
        }
    }

    const navigateToPreviousInvoicePage = () => {
        if (invoiceIndex.current > 0) {
            setInvoiceIndex({ ...invoiceIndex, current: --invoiceIndex.current });
        }
    }

    const navigateToCheckout = (event, reactivate = false) => {
        const button = event.target.closest("a") ?? event.target.closest("button");
        if (button) {
            const itemPriceId = button.id;
            if (reactivate) {
                dispatch(setCart({ count: 0, "items": {} }));
                navigate("/checkout/viewBillingDetails?isReactivation=true");
            }
            else {
                dispatch(setCart({ count: 1, "items": { "0": { "item": itemPriceId } } }));
                navigate("/checkout/viewBillingDetails");
            }
        }
    }

    const displayInvoicePageIndexOption = () => {
        var options = [];

        if (invoiceIndex.max === 0)
            return options;

        const leftArrow = (
            < button type="button" disabled={invoiceIndex.current === 0} onClick={navigateToPreviousInvoicePage} className="flex h-[32px] w-[32px] items-center justify-center gap-1 rounded-md bg-button-secondary text-blue-300 shadow-button hover:text-white active:text-white active:ring-1 active:ring-inset active:ring-blue-300/80 disabled:bg-gray-700 disabled:bg-none disabled:text-gray-500 disabled:shadow-none disabled:ring-transparent dark:bg-white dark:bg-none dark:text-blue-600 dark:ring-1 dark:ring-inset dark:ring-blue-200 dark:hover:bg-blue-100 dark:hover:ring-blue-300 dark:active:bg-blue-100 dark:active:ring-blue-500 dark:disabled:bg-gray-300 dark:disabled:text-gray-400 dark:disabled:ring-transparent" >
                <svg className="h-4 w-4 first:-ml-0.5 last:-mr-0.5 first:last:-mx-[5px]"><use href="/img/sprite.svg#arrow-left"></use></svg>
            </button >
        )

        const rightArrow = (
            <button type="button" disabled={invoiceIndex.current === invoiceIndex.max} onClick={navigateToNextInvoicePage} className="flex h-[32px] w-[32px] items-center justify-center gap-1 rounded-md bg-button-secondary text-blue-300 shadow-button hover:text-white active:text-white active:ring-1 active:ring-inset active:ring-blue-300/80 disabled:bg-gray-700 disabled:bg-none disabled:text-gray-500 disabled:shadow-none disabled:ring-transparent dark:bg-white dark:bg-none dark:text-blue-600 dark:ring-1 dark:ring-inset dark:ring-blue-200 dark:hover:bg-blue-100 dark:hover:ring-blue-300 dark:active:bg-blue-100 dark:active:ring-blue-500 dark:disabled:bg-gray-300 dark:disabled:text-gray-400 dark:disabled:ring-transparent">
                <svg className="h-4 w-4 first:-ml-0.5 last:-mr-0.5 first:last:-mx-[5px]"><use href="/img/sprite.svg#arrow-right"></use></svg>
            </button>
        )

        options.push(leftArrow);

        for (var i = 0; i <= invoiceIndex.max; i++) {
            options.push(
                <button id={i} type="button" onClick={updateInvoiceIndex} aria-current={i === invoiceIndex.current} className="flex h-[32px] w-[32px] items-center justify-center gap-1 rounded-md bg-button-secondary text-blue-300 shadow-button hover:text-white active:text-white active:ring-1 active:ring-inset active:ring-blue-300/80 disabled:bg-gray-700 disabled:bg-none disabled:text-gray-500 disabled:shadow-none disabled:ring-transparent dark:bg-white dark:bg-none dark:text-blue-600 dark:ring-1 dark:ring-inset dark:ring-blue-200 dark:hover:bg-blue-100 dark:hover:ring-blue-300 dark:active:bg-blue-100 dark:active:ring-blue-500 dark:disabled:bg-gray-300 dark:disabled:text-gray-400 dark:disabled:ring-transparent dark:aria-current:bg-blue-100 dark:aria-current:ring-blue-500">
                    <span>{`${i + 1}`}</span>
                </button>
            )
        }

        options.push(rightArrow);

        return (
            <div className="mt-5 flex items-center gap-2">
                {options}
            </div>
        );
    }


    const displayPlanMeta = (plan) => {
        const header = plan?.meta?.header;
        const features = plan?.meta?.data;

        return <>
            <p className="mt-0.5 mb-4">{upgradableSubscription?.plan?.meta?.header}</p>

            {
                features && features.map((data) => {
                    return <>
                        {
                            <>
                                <div className="mt-2.5 flex items-start gap-1.5">
                                    <svg className="h-5 w-4 shrink-0 text-green"><use href="/img/sprite.svg#check"></use></svg>
                                    <div className="text-h3 font-bold text-white" dangerouslySetInnerHTML={{ __html: data.header }} />
                                </div>
                                <p className="mt-0.5">{data.description}</p>
                            </>
                        }
                    </>
                })
            }
        </>
    }

    const displayAddonMeta = (addon) => {
        const header = addon?.meta?.whatsIncluded?.header;
        const features = addon?.meta?.whatsIncluded?.data;

        return <>
            {
                features && features.map((data) => {
                    return <>
                        <div className="mt-2.5 flex items-start gap-1.5">
                            <svg className="my-px h-4 w-4 shrink-0 text-green"><use href="/img/sprite.svg#check"></use></svg>
                            <h3 class={`${styles.h3}`} dangerouslySetInnerHTML={{ __html: data.header }} />
                        </div>
                        <div className="mt-0.5 text-sm">{data.description}</div>

                    </>
                })
            }
        </>
    }

    const getCardImg = (cardType) => {
        if (cardType === "Visa")
            return visa
        if (cardType === "Mastercard")
            return mastercard
        if (cardType === "AmericanExpress")
            return amex
        if (cardType === "Discover")
            return discover
        if (cardType === "Jcb")
            return jcb
        if (cardType === "DinersClub")
            return diners
        return null
    }

    const formatSubStatus = (status) => {
        if (status === "NonRenewing")
            return "Non Renewing"
        else if (status === "InTrial")
            return "In Trial"
        else
            return status
    }

    const handleCancellation = (e) => {

        const p = window.Brightback.handleDataPromise({
            app_id: cbRetentionAppId,
            subscription_id: subscription.subscriptionId,
            email: subscription.email,
            account: {
                internal_id: subscription.customerId
            },
            "save_return_url": window.location.href,
            "cancel_confirmation_url": window.location.href,
        });

        p.then((success) => {
            if (success.valid) {
                window.location.href = success.url;
            } else {
                //use your current cancelation flow
            }
        });
    }

    const termEnd = () => {
        var date = null;
        date = subscription?.currentTermEnd ?? subscription.trialEnd;
        return formatDate(date);
    }

    const billedPeriodText = () => {
        if (subscription.billingPeriodUnit === "Year")
            return t_label?.['billing.label.billed_annually'] ?? 'Billed annually'
        else
            return t_label?.['billing.label.billed_monthly'] ?? 'Billed monthly'
    }

    return (
        <>
            <div class={`dark ${styles.body} bg-white pt-22 md:pl-13 md:pt-0 lg:pl-50`}>
                <SideBar page="billing" />
                <div class="min-h-[100dvh] flex flex-col">
                    <div class="mb-auto">
                        {
                            (subscription?.plan != null || courseOnly) &&
                            <main class={`mx-auto box-content ${hideUpgradables ? "max-w-[540px]" : "max-w-[940px]"} p-5 md:py-10 lg:p-12`}>
                                <h1 className="text-h1 text-gray-900">{t_label?.['billing.label.billing'] ?? 'Billing'}</h1>


                                {
                                    subscription?.status === "Cancelled" && subscription?.cancelReason === paymentProcessingIssue &&
                                    <div className="mt-5 flex items-center gap-2.5 rounded-md bg-red-text p-5 text-white">
                                        <div className="flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-black/25">
                                            <svg className="h-5 w-5"><use href="/img/sprite.svg#wallet"></use></svg>
                                        </div>
                                        <p>{t_label?.['billing.message.plan_cancelled_due_to_payment_failure'] ?? 'Your plan was canceled because of an issue processing your recent payment. Please update your payment details below'}.</p>
                                    </div>
                                }

                                {
                                    subscription?.status !== "Cancelled" && subscription?.status !== "Paused" && isCTInvoiceDunning &&
                                    <div className="mt-5 flex items-center gap-2.5 rounded-md bg-red-text p-5 text-white">
                                        <div className="flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-black/25">
                                            <svg className="h-5 w-5"><use href="/img/sprite.svg#wallet"></use></svg>
                                        </div>
                                        <p>{t_message?.['billing.message.payment_processing_issue']}</p>
                                    </div>
                                }


                                {
                                    subscription?.status === "Cancelled" &&
                                    <div className="mt-5 rounded-md bg-gray-0 p-5 text-center">
                                        <h2 className="text-h1 text-gray-900">{t_message?.['billing.message.subscription_cancelled'] ?? 'Your Subscription was Canceled'}</h2>
                                        <p className="mt-2.5">{t_message?.['billing.message.renew_subscription_subtitle'] ?? 'Your financial success journey awaits! Renew your subscription today and never miss out on real-time market insights and expert analysis.'}</p>
                                        <a onClick={(e) => isMobileSubscription ? window.open(vvPlanPage2) : reactivateSub(e)} className="mt-6 flex h-[42px] items-center justify-center gap-1 rounded-md bg-gradient-to-b from-blue-600 to-blue-800 px-3 text-white shadow-button hover:shadow-button-hover active:shadow-button-active disabled:bg-gray-700 disabled:bg-none disabled:text-gray-500 disabled:shadow-none dark:bg-blue-600 dark:bg-none dark:shadow-none dark:hover:bg-blue-800 dark:active:bg-blue-900 dark:disabled:bg-gray-300 dark:disabled:text-gray-400 cursor-pointer">
                                            <span className="text-lg font-bold">{t_label?.['billing.button.restart_my_plan'] ?? 'Restart My Plan'}</span>
                                            <svg className="h-4 w-4 first:-ml-0.5 last:-mr-0.5 first:last:-mx-1"><use href="img/sprite.svg#arrow-up-right"></use></svg>
                                        </a>
                                    </div>
                                }

                                {
                                    subscription?.status === "Paused" &&
                                    <div className="mt-5 rounded-md bg-gray-0 p-5 text-center">
                                        <h2 className="text-h1 text-gray-900">{t_message?.['billing.message.subscription_paused'] ?? 'Your Subscription was Paused'}</h2>
                                        <p className="mt-2.5">{t_message?.['billing.message.resume_subscription_subtitle'] ?? 'Your financial success journey awaits! Resume your subscription today and never miss out on real-time market insights and expert analysis.'}</p>
                                        <a onClick={(e) => resumeSubs(e)} className="mt-6 flex h-[42px] items-center justify-center gap-1 rounded-md bg-gradient-to-b from-blue-600 to-blue-800 px-3 text-white shadow-button hover:shadow-button-hover active:shadow-button-active disabled:bg-gray-700 disabled:bg-none disabled:text-gray-500 disabled:shadow-none dark:bg-blue-600 dark:bg-none dark:shadow-none dark:hover:bg-blue-800 dark:active:bg-blue-900 dark:disabled:bg-gray-300 dark:disabled:text-gray-400 cursor-pointer">
                                            <span className="text-lg font-bold">{t_label?.['billing.button.resume_my_plan'] ?? 'Resume My Plan'}</span>
                                            <svg className="h-4 w-4 first:-ml-0.5 last:-mr-0.5 first:last:-mx-1"><use href="img/sprite.svg#arrow-up-right"></use></svg>
                                        </a>
                                    </div>
                                }


                                <div className="flex flex-col md:flex-row md:gap-5 lg:gap-10">
                                    {
                                        (!hideUpgradables) &&
                                        <div className="md:order-last md:w-80 md:shrink-0 lg:w-90">
                                            {/* <!-- Upgrade --> */}
                                            {
                                                upgradableSubscription?.plan &&
                                                <div className="mt-5 rounded-md bg-blue-1000 p-5 text-blue-200">
                                                    <h2 className="text-h2 font-bold text-white">{t_label?.['billing.label.upgrade_to'] ?? 'Upgrade to'} {upgradableSubscription?.plan?.name}</h2>

                                                    {
                                                        displayPlanMeta(upgradableSubscription?.plan)
                                                    }

                                                    <a id={upgradableSubscription?.plan?.id} onClick={navigateToCheckout} className="mt-6 flex h-[42px] items-center justify-center gap-1 rounded-md bg-gradient-to-b from-blue-600 to-blue-800 px-3 text-white shadow-button hover:shadow-button-hover active:shadow-button-active disabled:bg-gray-700 disabled:bg-none disabled:text-gray-500 disabled:shadow-none dark:bg-blue-600 dark:bg-none dark:shadow-none dark:hover:bg-blue-800 dark:active:bg-blue-900 dark:disabled:bg-gray-300 dark:disabled:text-gray-400 cursor-pointer">
                                                        <span className="text-lg font-bold">{t_label?.['billing.label.upgrade_my_plan'] ?? 'Upgrade My Plan'}</span>
                                                        <svg className="h-4 w-4 first:-ml-0.5 last:-mr-0.5 first:last:-mx-1"><use href="/img/sprite.svg#arrow-up-right"></use></svg>
                                                    </a>
                                                    <div className="mt-3 flex justify-center">
                                                        <a onClick={() => navigate("/billing/plans")} className="text-sm text-blue-300 underline hover:text-white cursor-pointer">{t_label?.['billing.button.view_all_plans'] ?? 'View All Plans'}</a>
                                                    </div>
                                                </div>
                                            }
                                            {/* <!--/ Upgrade --> */}

                                            {/* <!-- Explore Addons --> */}
                                            {
                                                upgradableSubscription.addons &&
                                                <div className="mt-5 rounded-md bg-gray-0 p-5">
                                                    <h2 className="text-h2 font-bold text-gray-900">{t_label?.['billing.button.explore_addons'] ?? 'Explore Add-ons'}</h2>
                                                    <div className="mt-4 flex flex-col gap-2.5">

                                                        {
                                                            upgradableSubscription?.addons.filter((addon) => { return (addon?.isPurchased === false && addon?.isIncluded === false) })
                                                                .map((addon) => {
                                                                    return (
                                                                        <a data-toggle={`#modal-${addon.prices.id}`} className="rounded-md bg-white py-2.5 px-4 shadow-card hover:bg-gray-100 cursor-pointer">
                                                                            <div className="flex items-center justify-between">
                                                                                <div className="text-lg font-bold text-gray-900">{addon.name}</div>
                                                                                {
                                                                                    subscription?.status !== "InTrial" &&
                                                                                    <div className="">
                                                                                        {displayPrice(addon.prices.amount, upgradableSubscription.currencyCode)}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <div className="mt-1 text-sm">{addon.description}</div>
                                                                        </a>
                                                                    )
                                                                })
                                                        }

                                                        {
                                                            upgradableSubscription?.charges &&
                                                            upgradableSubscription?.charges.filter((charge) => { return (charge?.isPurchased === false && charge?.isIncluded === false) })
                                                                .map((charge) => {
                                                                    return (
                                                                        <a data-toggle={`#modal-${charge.id}`} className="rounded-md bg-white py-2.5 px-4 shadow-card hover:bg-gray-100 cursor-pointer">
                                                                            <div className="flex items-center justify-between">
                                                                                <div className="text-lg font-bold text-gray-900">{charge.name}</div>
                                                                                {
                                                                                    subscription?.status !== "InTrial" &&
                                                                                    <div className="">
                                                                                        {displayPrice(charge.amount, upgradableSubscription.currencyCode)}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <div className="mt-1 text-sm">{charge?.description}</div>
                                                                        </a>
                                                                    )
                                                                })
                                                        }

                                                        {
                                                            (upgradableSubscription?.addons.filter((addon) => {
                                                                return (addon.isPurchased === true || addon.isIncluded === true)
                                                            }).length > 0) &&
                                                            <hr className="my-1.5 border-gray-300/50" />
                                                        }

                                                        {
                                                            upgradableSubscription?.charges &&
                                                            upgradableSubscription?.addons.filter((addon) => { return (addon.isPurchased === true || addon.isIncluded === true) })
                                                                .map((addon) => {
                                                                    return (
                                                                        <div className="rounded-md bg-white py-2.5 px-4 shadow-card">
                                                                            <div className="flex items-center justify-between">
                                                                                <div className="text-lg font-bold text-gray-500">{addon.name}</div>
                                                                                <div className="flex gap-1 text-xxs font-extrabold uppercase text-gray-400">
                                                                                    <span>{addon.isPurchased ? t_label?.['billing.label.purchased'] : t_label?.['billing.label.included']}</span>
                                                                                    <svg className="h-3 w-3"><use href="/img/sprite.svg#check-double"></use></svg>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="mt-1 text-sm text-gray-400"></div> */}
                                                                        </div>
                                                                    )
                                                                })
                                                        }

                                                        {

                                                            upgradableSubscription?.charges.filter((charge) => { return (charge.isPurchased === true || charge.isIncluded === true) })
                                                                .map((charge) => {
                                                                    return (
                                                                        <div className="rounded-md bg-white py-2.5 px-4 shadow-card">
                                                                            <div className="flex items-center justify-between">
                                                                                <div className="text-lg font-bold text-gray-500">{charge.name}</div>
                                                                                <div className="flex gap-1 text-xxs font-extrabold uppercase text-gray-400">
                                                                                    <span>{charge.isPurchased ? t_label?.['billing.label.purchased'] : t_label?.['billing.label.included']}</span>
                                                                                    <svg className="h-3 w-3"><use href="/img/sprite.svg#check-double"></use></svg>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="mt-1 text-sm text-gray-400">Manage your portfolio with flawless precision</div> */}
                                                                        </div>
                                                                    )
                                                                })
                                                        }

                                                    </div>
                                                </div>
                                            }

                                            {/* <!--/ Explore Addons --> */}

                                        </div>
                                    }

                                    <hr className="mt-5 border-gray-300/50 md:hidden" />
                                    <div className="md:grow">
                                        {/* <!-- Payment History --> */}
                                        <div className="mt-5 rounded-md bg-gray-0 p-5">
                                            {
                                                subscription.plan &&
                                                <>
                                                    <div className="lg:flex lg:items-center lg:justify-between">
                                                        <h1 className="text-h1 text-gray-900">{subscription.plan}</h1>
                                                        <div className="mt-1 flex items-baseline lg:mt-0">
                                                            <div className="text-sm lg:text-md">{t_label?.['billing.label.status'] ?? 'Status'}: <span className="text-gray-900">{formatSubStatus(subscription?.status)}</span></div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-1.5 flex items-center justify-between lg:mt-2">
                                                        {
                                                            (subscription?.cancelledAt == null) &&
                                                            <>
                                                                {
                                                                    (subscription?.status != "Active" && subscription?.status != "InTrial") &&
                                                                    <div className="text-sm lg:order-last lg:text-md">{billedPeriodText()}: <span className="text-gray-900">{displayPrice(subscription.billingPeriodUnit === "Year" ? subscription.perAnnual : subscription.perMonth, subscription.currencyCode)}</span></div>
                                                                }
                                                                {
                                                                    (subscription?.status === "Active" || subscription?.status === "InTrial") &&
                                                                    <div className="text-sm lg:order-last lg:text-md">{t_label?.['checkout.label.next_billing_amount']}: <span className="text-gray-900">{displayPrice(subscription.nextBillingAmount, subscription.currencyCode)}</span></div>
                                                                }
                                                            </>
                                                        }
                                                        {
                                                            subscription?.status === "Active" &&
                                                            <div className="text-sm lg:text-md">{t_label?.['billing.label.renews'] ?? 'Renews'}: <span className="text-gray-900">{formatDate(subscription.renewal)}</span></div>
                                                        }
                                                        {
                                                            (subscription?.status === "Cancelled") &&
                                                            <div className="text-sm lg:text-md">{t_label?.['billing.label.cancelled_on'] ?? 'Cancelled on'}: <span className="text-gray-900">{formatDate(subscription.cancelledAt)}</span></div>
                                                        }
                                                        {
                                                            (subscription.cancelledAt != null && subscription?.status != "Cancelled") &&
                                                            <div className="text-sm lg:text-md">{t_label?.['checkout.label.ending_on'] ?? 'Ending on'}: <span className="text-gray-900">{formatDate(subscription.cancelledAt)}</span></div>
                                                        }
                                                        {
                                                            subscription?.status === "Paused" &&
                                                            <div className="text-sm lg:text-md">{t_label?.['billing.label.paused_on'] ?? 'Paused On'}: <span className="text-gray-900">{formatDate(subscription.pausedAt)}</span></div>
                                                        }

                                                        {
                                                            (subscription?.status === "InTrial" && subscription.cancelledAt == null) &&
                                                            <div className="text-sm lg:text-md">{t_label?.['checkout.label.trial_ends_on'] ?? 'Trial Ends On'}: <span className="text-gray-900">{formatDate(subscription.trialEnd)}</span></div>
                                                        }
                                                    </div>
                                                </>
                                            }

                                            <div className={courseOnly ? `-mx-2.5` : `-mx-2.5 mt-5`}>
                                                <table className="w-full">
                                                    <thead className="bg-gray-200 text-sm">
                                                        <tr>
                                                            <th className="px-2.5 py-1.5 text-left font-medium">{t_label?.['billing.label.date'] ?? 'Date'}</th>
                                                            <th className="px-2.5 py-1.5 text-left font-medium">{t_label?.['billing.label.amount'] ?? 'Amount'}</th>
                                                            <th width="30%" className="px-2.5 py-1.5 text-left font-medium">{t_label?.['billing.label.receipt'] ?? 'Receipt'}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            getCurrentPageInvoice().map((invoice) => {
                                                                return (
                                                                    <tr className="border-t border-gray-300/50 first:border-0 h-[36px]">
                                                                        <td className="px-2.5 py-1.5">{formatDate(invoice.date)}</td>
                                                                        <td className="px-2.5 py-1.5 text-gray-900">{displayPrice(invoice.amount, subscription.currencyCode)}</td>
                                                                        <td className="px-2.5 py-1.5">
                                                                            <button type="button" id={invoice.invoiceId} onClick={(event) => downloadInvoiceAttachment(invoice)} className="flex items-center justify-center gap-1 text-sm text-blue-300 hover:text-white active:text-white disabled:text-gray-500 dark:text-blue-600 dark:hover:text-blue-800 dark:active:text-blue-900 dark:disabled:text-gray-400">
                                                                                <svg className="h-4 w-4"><use href="/img/sprite.svg#download"></use></svg>
                                                                                <span><span className="hidden lg:inline">{t_label?.['billing.button.download'] ?? 'Download'} </span>{t_label?.['billing.label.pdf'] ?? 'PDF'}</span>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                            {
                                                displayInvoicePageIndexOption()
                                            }

                                        </div>
                                        {/* <!--/ Payment History --> */}

                                        {/* <!-- Courses --> */}

                                        {
                                            courses.length > 0 &&
                                            <>
                                                < div className="mt-5 rounded-md bg-gray-0 p-5">
                                                    <h2 className="text-h2 font-bold text-gray-900">{t_label?.['billing.label.courses'] ?? 'Courses'}</h2>
                                                    <div className="mt-4 flex flex-col gap-2.5">

                                                        {
                                                            courses.map((course) => {
                                                                return (
                                                                    <div className='rounded-md bg-white py-2.5 px-4 shadow-card hover:bg-gray-100 cursor-pointer'>
                                                                        <div className="flex items-center justify-between">
                                                                            <div className="text-lg font-bold text-gray-900">{course.course}</div>
                                                                            <div className="text-gray-200 text-sm">{t_label?.['billing.label.ending_at'] ?? 'Ending At'} : {course.expiryDate}</div>
                                                                        </div>
                                                                        {/* <div className="mt-1 text-sm">{course.description} xyadjfksdfjd</div> */}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {/* <!--/ Courses --> */}


                                        {/* <!-- Payment Details --> */}

                                        {!isMobileSubscription &&
                                            <div className="mt-5 rounded-md bg-gray-0 p-5">
                                                <div className="flex items-center justify-between">
                                                    <div className="flex flex-wrap items-center gap-y-2 gap-x-3">
                                                        <h2 className="text-h2 font-bold text-gray-900">{t_label?.['billing.label.payment_details'] ?? 'Payment Details'}</h2>
                                                        {
                                                            ((subscription?.status === "Cancelled" && subscription?.cancelReason === paymentProcessingIssue) || (subscription?.status !== "Cancelled" && subscription?.status !== "Paused" && isCTInvoiceDunning)) &&
                                                            <div className="-my-px flex h-5 items-center gap-0.5 rounded-full bg-red-bg px-1 text-red">
                                                                <svg className="h-3 w-3"><use href="img/sprite12.svg#info"></use></svg>
                                                                <span className="px-0.5 text-xxs font-extrabold uppercase">{t_label?.['billing.label.action_required'] ?? 'Action required'}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="-my-1 cursor-pointer">
                                                        <a onClick={() => handleUpdatePM()} className="flex h-[26px] font-bold items-center justify-center gap-1 rounded-md border border-gray-600 px-2.5-px text-sm hover:border-gray-500 hover:text-gray-300 active:border-gray-400 active:text-gray-200 disabled:border-gray-800 disabled:text-gray-600 dark:border-gray-300 dark:text-gray-600 dark:hover:bg-gray-200 dark:active:border-gray-500 dark:disabled:border-gray-300 dark:disabled:bg-transparent dark:disabled:text-gray-400">
                                                            <svg className="h-4 w-4 text-gray-400 first:-ml-0.5 last:-mr-0.5 first:last:-mx-[5px]"><use href="/img/sprite.svg#edit"></use></svg>
                                                            <span>{t_label?.['account.label.edit'] ?? 'Edit'}</span>
                                                        </a>
                                                    </div>
                                                </div>
                                                {
                                                    subscription?.card &&
                                                    <div className="mt-5 flex items-center gap-1.5">
                                                        {
                                                            subscription?.card?.cardType != null && getCardImg(subscription?.card?.cardType) &&
                                                            <img src={getCardImg(subscription?.card?.cardType)} width="30" height="18" />
                                                        }
                                                        <div className="text-h3">{t_label?.['billing.label.ending_in'] ?? 'Ending in'} <span className="font-bold text-gray-900">{subscription.card.last4}</span> {t_label?.['billing.label.expiring'] ?? 'expiring'} <span className="font-bold text-gray-900">{`${subscription.card.expiryMonth}/${subscription.card.expiryYear}`}</span></div>
                                                    </div>
                                                }
                                            </div>
                                        }

                                        {isMobileSubscription &&
                                            <div className="mt-5 rounded-md bg-gray-0 p-5">
                                                <div className="flex items-center justify-between">
                                                    <div className="flex flex-wrap items-center gap-y-2 gap-x-3">
                                                        <h2 className="text-h2 font-bold text-gray-900">{t_label?.['billing.label.payment_details'] ?? 'Payment Details'}</h2>
                                                    </div>
                                                </div>
                                                <div className="mt-3 flex items-center gap-1.5">
                                                    <div className="text-h3 font-medium">{t_message?.['billing.message.mobile_subscription_payment_method'] ?? 'Paid through mobile app store'}</div>
                                                </div>
                                            </div>
                                        }
                                        {/* <!--/ Payment Details --> */}

                                        {
                                            subscription.status !== "Cancelled" && subscription.status !== "Paused" && !courseOnly &&
                                            <>
                                                {
                                                    !isMobileSubscription &&
                                                    <>
                                                        <hr className="mt-5 border-gray-300/50" />

                                                        {/* <!-- Manage Subscription --> */}
                                                        <div className="mt-5 flex flex-col items-start gap-3">
                                                            <h3 class={`${styles.h3} text-h3 text-gray-900`}>{t_label?.['billing.button.manage_subscription'] ?? 'Manage Subscription'}</h3>
                                                            <p>{getInterpolatedString(t_message?.['billing.message.manage_subscription_subtitle'], {
                                                                'termEnd()': termEnd()
                                                            })}</p>
                                                            <button type="button" data-toggle="#modal-manage" className="flex h-[32px] items-center justify-center gap-1 rounded-md bg-gray-700 px-3 text-blue-300 ring-1 ring-inset ring-blue-300/30 hover:text-white hover:shadow-button-ter-hover active:text-white active:shadow-button-ter-hover active:ring-blue-300/80 disabled:text-gray-500 disabled:shadow-none disabled:ring-transparent dark:bg-blue-100 dark:text-blue-600 dark:ring-blue-200 dark:hover:bg-blue-200 dark:hover:ring-blue-300 dark:active:bg-blue-300 dark:active:ring-blue-500 dark:disabled:bg-gray-300 dark:disabled:text-gray-400 dark:disabled:ring-transparent">
                                                                <span>{t_label?.['billing.button.manage_subscription'] ?? 'Manage Subscription'}</span>
                                                            </button>
                                                        </div>
                                                        {/* <!--/ Manage Subscription --> */}
                                                    </>
                                                }
                                                {
                                                    isMobileSubscription &&
                                                    <>
                                                        <hr className="mt-5 border-gray-300/50" />

                                                        {/* <!-- Manage Subscription --> */}
                                                        <div className="mt-5 flex flex-col items-start gap-3">
                                                            <h3 class={`${styles.h3} text-h3 text-gray-900`}>Manage Subscription</h3>
                                                            <p>{t_message?.['billing.message.manage_mobile_subscription_subtitle'] ?? "Since you signed up for VectorVest through a mobile app store, you will need to handle your subscription through the app store's settings."}</p>
                                                            {
                                                                subscription?.channel == 'AppStore' &&
                                                                <button type="button" data-toggle="#modal-apple-app-store" className="flex h-[32px] items-center justify-center rounded-md bg-gray-700 px-3 text-blue-300 ring-1 ring-inset ring-blue-300/30 hover:text-white hover:shadow-button-ter-hover active:text-white active:shadow-button-ter-hover active:ring-blue-300/80 disabled:text-gray-500 disabled:shadow-none disabled:ring-transparent dark:bg-blue-100 dark:text-blue-600 dark:ring-blue-200 dark:hover:bg-blue-200 dark:hover:ring-blue-300 dark:active:bg-blue-300 dark:active:ring-blue-500 dark:disabled:bg-gray-300 dark:disabled:text-gray-400 dark:disabled:ring-transparent">
                                                                    <span>{t_label?.['billing.label.apple_app_store_info'] ?? 'Apple App Store Info'}</span>
                                                                </button>
                                                            }
                                                            {subscription?.channel == 'PlayStore' &&
                                                                <button type="button" data-toggle="#modal-google-play-store" className="flex h-[32px] items-center justify-center rounded-md bg-gray-700 px-3 text-blue-300 ring-1 ring-inset ring-blue-300/30 hover:text-white hover:shadow-button-ter-hover active:text-white active:shadow-button-ter-hover active:ring-blue-300/80 disabled:text-gray-500 disabled:shadow-none disabled:ring-transparent dark:bg-blue-100 dark:text-blue-600 dark:ring-blue-200 dark:hover:bg-blue-200 dark:hover:ring-blue-300 dark:active:bg-blue-300 dark:active:ring-blue-500 dark:disabled:bg-gray-300 dark:disabled:text-gray-400 dark:disabled:ring-transparent">
                                                                    <span>{t_label?.['billing.label.google_play_store_info'] ?? 'Google Play Store Info'}</span>
                                                                </button>
                                                            }

                                                        </div>
                                                        {/* <!--/ Manage Subscription --> */}
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </main>
                        }
                    </div>
                    <Footer />
                </div>
            </div >

            <div aria-hidden="true" id="modal-manage" className="dark group/modal fixed inset-0 flex overflow-auto bg-gray-1000/70 pt-5 transition-opacity aria-hidden:pointer-events-none aria-hidden:opacity-0 sm:p-5">
                <div data-toggle="#modal-manage" className="fixed inset-0 cursor-pointer"></div>
                <div className="relative m-auto mb-0 w-full rounded-t-2xl bg-gray-0 p-8 transition-transform group-aria-hidden/modal:translate-y-10 sm:mb-auto sm:max-w-[540px] sm:rounded-md">
                    <button type="button" data-toggle="#modal-manage" className="absolute top-2.5 right-2.5 hover:text-gray-600">
                        <svg className="h-6 w-6"><use href="/img/sprite24.svg#close"></use></svg>
                    </button>
                    <div className="text-h1 text-gray-900">{t_label?.['billing.button.manage_subscription'] ?? 'Manage Subscription'}</div>
                    <div className="mt-7 rounded-md bg-white py-3 px-5 shadow-card sm:flex sm:items-center sm:justify-between">
                        <div className="mb-2.5">
                            <div className="text-h2 font-bold text-gray-900">{subscription.plan}</div>
                            <div className="mt-1 flex items-center gap-2.5">
                                <div className="text-h3 font-bold text-gray-900">{displayPrice(subscription.billingPeriodUnit === "Year" ? subscription.perAnnual : subscription.perMonth, subscription.currencyCode)}</div>
                                <div className="text-gray-500">{billedPeriodText()}</div>
                            </div>
                            <div className="mt-1 flex items-center gap-2.5">

                                {
                                    subscription?.card?.cardType != null && getCardImg(subscription?.card?.cardType) &&
                                    <>
                                        <div className="text-h3">{t_label?.['billing.label.paid_via'] ?? 'Paid via'}</div>
                                        <img src={getCardImg(subscription?.card?.cardType)} width="30" height="18" />
                                    </>
                                }

                            </div>
                        </div>
                        <button type="button" onClick={() => navigate("/billing/plans")} className="flex font-bold items-center justify-center gap-1 text-sm text-blue-300 hover:text-white active:text-white disabled:text-gray-500 dark:text-blue-600 dark:hover:text-blue-800 dark:active:text-blue-900 dark:disabled:text-gray-400">
                            <span>{t_label?.['billing.label.change'] ?? 'Change'}</span>
                            <svg className="h-4 w-4">
                                <use href="/img/sprite.svg#chevron-right"></use>
                            </svg>
                        </button>
                    </div>
                    <div className="mt-7 grid grid-cols-1 gap-2.5 sm:grid-cols-2">
                        <a onClick={handleCancellation} className="flex h-[32px] items-center justify-center gap-1 rounded-md border border-gray-600 px-3-px hover:border-gray-500 hover:text-gray-300 active:border-gray-400 active:text-gray-200 disabled:border-gray-800 disabled:text-gray-600 dark:border-gray-300 dark:text-gray-600 dark:hover:bg-gray-200 dark:active:border-gray-500 dark:disabled:border-gray-300 dark:disabled:bg-transparent dark:disabled:text-gray-400 cursor-pointer">
                            <span>{t_label?.['billing.button.cancel_subscription'] ?? 'Cancel Subscription'}</span>
                            <svg className="h-4 w-4 first:-ml-0.5 last:-mr-0.5 first:last:-mx-1"><use href="/img/sprite.svg#arrow-up-right"></use></svg>
                        </a>
                        <button onClick={() => navigate("/billing/pause")} type="button" className="flex h-[32px] items-center justify-center gap-1 rounded-md border border-gray-600 px-3-px hover:border-gray-500 hover:text-gray-300 active:border-gray-400 active:text-gray-200 disabled:border-gray-800 disabled:text-gray-600 dark:border-gray-300 dark:text-gray-600 dark:hover:bg-gray-200 dark:active:border-gray-500 dark:disabled:border-gray-300 dark:disabled:bg-transparent dark:disabled:text-gray-400">
                            <span>{t_label?.['billing.button.pause_subscription'] ?? 'Pause Subscription'}</span>
                            <svg className="h-4 w-4 first:-ml-0.5 last:-mr-0.5 first:last:-mx-1"><use href="/img/sprite.svg#arrow-up-right"></use></svg>
                        </button>
                    </div>
                </div>
            </div>

            <div aria-hidden="true" id="modal-apple-app-store" className="dark group/modal fixed inset-0 flex overflow-auto bg-gray-1000/70 pt-5 transition-opacity aria-hidden:pointer-events-none aria-hidden:opacity-0 sm:p-5">
                <div data-toggle="#modal-apple-app-store" className="fixed inset-0 cursor-pointer"></div>
                <div className="relative m-auto mb-0 w-full rounded-t-2xl bg-white p-8 transition-transform group-aria-hidden/modal:translate-y-10 sm:mb-auto sm:max-w-[540px] sm:rounded-md">
                    <button type="button" data-toggle="#modal-apple-app-store" className="absolute top-2.5 right-2.5 hover:text-gray-600">
                        <svg className="h-6 w-6"><use href="/img/sprite24.svg#close"></use></svg>
                    </button>
                    <div className="text-h1 text-gray-900 text-center">{t_label?.['billing.label.apple_app_store'] ?? 'Apple App Store'}</div>
                    <div className="mt-4 rounded-md py-3 px-3 shadow-card bg-gray-0 sm:flex sm:items-center sm:justify-between">
                        <div className="flex flex-col text-md gap-3">
                            <div dangerouslySetInnerHTML={{ __html: t_message?.['billing.message.apple_app_store_subscription.manage'] }} />
                            <div>{t_message?.['billing.message.apple_app_store_subscription.manage_details']}</div>
                            <a href="https://support.apple.com/en-us/HT202039" target="_blank" className="text-blue-300 hover:text-white active:text-white disabled:text-gray-500 dark:text-blue-600 dark:hover:text-blue-800 dark:active:text-blue-900 dark:disabled:text-gray-400">
                                {t_message?.['billing.message.apple_app_store_subscription.cancel_link']}
                            </a>
                            <a href="https://support.apple.com/en-us/HT204084" target="_blank" className="text-blue-300 hover:text-white active:text-white disabled:text-gray-500 dark:text-blue-600 dark:hover:text-blue-800 dark:active:text-blue-900 dark:disabled:text-gray-400">
                                {t_message?.['billing.message.apple_app_store_subscription.request_refund_link']}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div aria-hidden="true" id="modal-google-play-store" className="dark group/modal fixed inset-0 flex overflow-auto bg-gray-1000/70 pt-5 transition-opacity aria-hidden:pointer-events-none aria-hidden:opacity-0 sm:p-5">
                <div data-toggle="#modal-google-play-store" className="fixed inset-0 cursor-pointer"></div>
                <div className="relative m-auto mb-0 w-full rounded-t-2xl bg-white p-8 transition-transform group-aria-hidden/modal:translate-y-10 sm:mb-auto sm:max-w-[540px] sm:rounded-md">
                    <button type="button" data-toggle="#modal-google-play-store" className="absolute top-2.5 right-2.5 hover:text-gray-600">
                        <svg className="h-6 w-6"><use href="/img/sprite24.svg#close"></use></svg>
                    </button>
                    <div className="text-h1 text-gray-900 text-center">{t_label?.['billing.label.google_play_store'] ?? 'Google Play Store'}</div>
                    <div className="mt-4 rounded-md py-3 px-3 shadow-card bg-gray-0 sm:flex sm:items-center sm:justify-between">
                        <div className="flex flex-col text-md gap-3">
                            <div>{t_message?.['billing.message.google_play_store_subscription.manage_instruction']}</div>
                            <div className='flex flex-col gap-1 px-1'>

                                <div>{t_message?.['billing.message.google_play_store_subscription.manage_instruction_1']}</div>
                                <div>{t_message?.['billing.message.google_play_store_subscription.manage_instruction_2']}</div>
                                <div dangerouslySetInnerHTML={{ __html: t_message?.['billing.message.google_play_store_subscription.manage_instruction_3'] }} />
                                <div>{t_message?.['billing.message.google_play_store_subscription.manage_instruction_4']}</div>
                                <div dangerouslySetInnerHTML={{ __html: t_message?.['billing.message.google_play_store_subscription.manage_instruction_5'] }} />


                            </div>
                            <div>{t_message?.['billing.message.google_play_store_subscription.manage_link']}</div>
                            <a href="https://support.google.com/googleplay/answer/7018481?hl=en&co=GENIE.Platform%3DAndroid" target="_blank" className="text-blue-300 hover:text-white active:text-white disabled:text-gray-500 dark:text-blue-600 dark:hover:text-blue-800 dark:active:text-blue-900 dark:disabled:text-gray-400">
                                {t_message?.['billing.message.google_play_store_subscription.cancel_link']}
                            </a>
                            <a href="https://support.google.com/googleplay/answer/2479637?hl=en" target="_blank" className="text-blue-300 hover:text-white active:text-white disabled:text-gray-500 dark:text-blue-600 dark:hover:text-blue-800 dark:active:text-blue-900 dark:disabled:text-gray-400">
                                {t_message?.['billing.message.google_play_store_subscription.request_refund_link']}
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            {
                upgradableSubscription.addons && upgradableSubscription.addons.map((addon) => {
                    return (
                        <div aria-hidden="true" id={`modal-${addon.prices.id}`} class={`dark ${styles.body} group/modal fixed inset-0 flex overflow-auto bg-gray-1000/70 pt-5 transition-opacity aria-hidden:pointer-events-none aria-hidden:opacity-0 sm:p-5`}>
                            <div data-toggle={`#modal-${addon.prices.id}`} className="fixed inset-0 cursor-pointer"></div>
                            <div className="relative m-auto mb-0 w-full rounded-t-md bg-white p-8 transition-transform group-aria-hidden/modal:translate-y-10 sm:mb-auto sm:max-w-[400px] sm:rounded-b-md">
                                <button type="button" data-toggle={`#modal-${addon.prices.id}`} className="absolute top-2.5 right-2.5 hover:text-gray-600">
                                    <svg className="h-6 w-6"><use href="/img/sprite24.svg#close"></use></svg>
                                </button>
                                <div className="text-center text-h1 text-gray-900">{addon.name}</div>
                                <div className="mt-1.5 text-center text-gray-575">{addon?.meta?.whatsIncluded?.header}</div>
                                <div className="mt-5 rounded-md bg-gray-200 p-4 text-gray-600">
                                    <div className="font-extrabold text-gray-800">{t_label?.['billing.label.with']} {addon.name}:</div>

                                    {displayAddonMeta(addon)}

                                    <div className="mt-2.5 flex">
                                        <a href={addon?.meta?.learnMore} target="_blank" className="flex items-center justify-center gap-1 text-sm text-blue-300 hover:text-white active:text-white disabled:text-gray-500 dark:text-blue-600 dark:hover:text-blue-800 dark:active:text-blue-900 dark:disabled:text-gray-400">
                                            <span>{t_label?.['billing.label.learn_more']}</span>
                                        </a>
                                    </div>
                                </div>

                                <button type="button" id={addon.prices.id} onClick={navigateToCheckout} className="mt-5 flex h-[42px] w-full items-center justify-center gap-1 rounded-md bg-gradient-to-b from-blue-600 to-blue-800 px-3 text-white shadow-button hover:shadow-button-hover active:shadow-button-active disabled:bg-gray-700 disabled:bg-none disabled:text-gray-500 disabled:shadow-none dark:bg-blue-600 dark:bg-none dark:shadow-none dark:hover:bg-blue-800 dark:active:bg-blue-900 dark:disabled:bg-gray-300 dark:disabled:text-gray-400">

                                    <span className="text-lg font-bold">
                                        {
                                            subscription?.status !== "InTrial" ?
                                                <>{t_label?.['billing.label.buy_for'] ?? 'Buy for'} {displayPrice(addon.prices.amount, upgradableSubscription.currencyCode)}/{addon.prices.periodUnit.toLowerCase()} </> : <>{t_label?.['billing.label.start_free_trial'] ?? 'Start Free Trial'}</>
                                        }
                                    </span>
                                    <svg className="h-4 w-4 first:-ml-0.5 last:-mr-0.5 first:last:-mx-1"><use href="/img/sprite.svg#arrow-right"></use></svg>


                                </button>

                                {
                                    subscription?.status === "InTrial" && <>
                                        <div className="text-sm mt-1">
                                            {/* Addon trial ends with plan trial. Billed {displayPrice(addon.prices.amount, upgradableSubscription.currencyCode)}/{addon.prices.periodUnit.toLowerCase()} after the trial period */}
                                            <Localization text={t_message?.['billing.message.addon_trial_billing']} values={{
                                                'amount': displayPrice(addon.prices.amount, upgradableSubscription.currencyCode),
                                                'periodUnit': addon.prices.periodUnit.toLowerCase()
                                            }} />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    )
                })
            }

            {
                upgradableSubscription.charges && upgradableSubscription.charges.map((charge) => {
                    return (
                        <div aria-hidden="true" id={`modal-${charge.id}`} class={`dark ${styles.body} group/modal fixed inset-0 flex overflow-auto bg-gray-1000/70 pt-5 transition-opacity aria-hidden:pointer-events-none aria-hidden:opacity-0 sm:p-5`}>
                            <div data-toggle={`#modal-${charge.id}`} className="fixed inset-0 cursor-pointer"></div>
                            <div className="relative m-auto mb-0 w-full rounded-t-md bg-white p-8 transition-transform group-aria-hidden/modal:translate-y-10 sm:mb-auto sm:max-w-[400px] sm:rounded-b-md">
                                <button type="button" data-toggle={`#modal-${charge.id}`} className="absolute top-2.5 right-2.5 hover:text-gray-600">
                                    <svg className="h-6 w-6"><use href="/img/sprite24.svg#close"></use></svg>
                                </button>
                                <div className="text-center text-h1 text-gray-900">{charge.name}</div>
                                <div className="mt-1.5 text-center text-gray-575">{charge?.meta?.whatsIncluded?.header}</div>
                                <div className="mt-5 rounded-md bg-gray-200 p-4 text-gray-600">
                                    <div className="font-extrabold text-gray-800">{t_label?.['billing.label.with']} {charge.name}:</div>

                                    {displayAddonMeta(charge)}

                                    <div className="mt-2.5 flex">
                                        <a href={charge?.meta?.learnMore} target="_blank" className="flex items-center justify-center gap-1 text-sm text-blue-300 hover:text-white active:text-white disabled:text-gray-500 dark:text-blue-600 dark:hover:text-blue-800 dark:active:text-blue-900 dark:disabled:text-gray-400">
                                            <span>{t_label?.['billing.label.learn_more']}</span>
                                        </a>
                                    </div>
                                </div>

                                <button type="button" id={charge.id} onClick={navigateToCheckout} className="mt-5 flex h-[42px] w-full items-center justify-center gap-1 rounded-md bg-gradient-to-b from-blue-600 to-blue-800 px-3 text-white shadow-button hover:shadow-button-hover active:shadow-button-active disabled:bg-gray-700 disabled:bg-none disabled:text-gray-500 disabled:shadow-none dark:bg-blue-600 dark:bg-none dark:shadow-none dark:hover:bg-blue-800 dark:active:bg-blue-900 dark:disabled:bg-gray-300 dark:disabled:text-gray-400">

                                    <span className="text-lg font-bold">
                                        {t_label?.['billing.label.buy_for'] ?? 'Buy for'} {displayPrice(charge.amount, upgradableSubscription.currencyCode)}
                                        {/* {
                                            subscription?.status !== "InTrial" ?
                                                <>Buy for {displayPrice(charge.amount, upgradableSubscription.currencyCode)}</> : <>Start Free Trial</>
                                        } */}
                                    </span>
                                    <svg className="h-4 w-4 first:-ml-0.5 last:-mr-0.5 first:last:-mx-1"><use href="/img/sprite.svg#arrow-right"></use></svg>


                                </button>

                                {/* {
                                    subscription?.status === "InTrial" && <>
                                        <div className="text-sm mt-1">
                                            Addon trial ends with plan trial. Billed {displayPrice(charge.amount, upgradableSubscription.currencyCode)}/{charge.periodUnit.toLowerCase()} after the trial period
                                        </div>
                                    </>
                                } */}
                            </div>
                        </div>
                    )
                })
            }


        </>
    )
}

export default ViewBilling